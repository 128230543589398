<template>
     <footer id="footer" class="footer position-relative">

<div class="container footer-top">
  <div class="row gy-4 white">
    <div class="col-lg-4 col-md-6 footer-about">
      <a href="#" class="logo d-flex align-items-center white">
        <span class="sitename white" style="color: #fff;">PT. Rumpun Alam Indonesia</span>
      </a>
      <div class="footer-contact pt-3">
        <p>Jl. Raya Nanggalo Nomer 28 A, Kp. Olo, Nanggalo District, Padang City, West Sumatera, 25173 - Indonesia </p>
        <p class="mt-3"><strong>Phone:</strong> <span>+6282374635328 (Alno Ramalino)</span></p>
        <p class="mt-3"><strong>Phone:</strong> <span>+6281275341600 (Anjas)</span></p>
        <p><strong>Email:</strong> <span>rumpunalamindonesia@gmail.com</span></p>
      </div>
      <div class="social-links d-flex mt-4" style="color: #ffffff;">
        <a href="https://www.instagram.com/pt.rumpunalamindonesia/?utm_source=ig_web_button_share_sheet" style="color: #ffffff;"><i class="bi bi-instagram" style="color: #ffffff;"></i></a>
        <!-- <a href=""><i class="bi bi-linkedin"></i></a> -->
      </div>
    </div>

    <!-- <div class="col-lg-2 col-md-3 footer-links">
      <h4>Our Product</h4>
      <ul>
        <li><a href="/">Vanilla</a></li>
        <li><a href="#t">Cardamom</a></li>
        <li><a href="#">Cinnamon</a></li>
        <li><a href="#">Nutmeg</a></li>
      </ul>
    </div> -->

    <!-- <div class="col-lg-2 col-md-3 footer-links">
      <h4>Our Services</h4>
      <ul>
        <li><a href="#">Web Design</a></li>
        <li><a href="#">Web Development</a></li>
        <li><a href="#">Product Management</a></li>
        <li><a href="#">Marketing</a></li>
        <li><a href="#">Graphic Design</a></li>
      </ul>
    </div> -->

  </div>
</div>

<div class="container copyright text-center mt-4">
  <p class="white">© <span>Copyright</span> <strong class="px-1 sitename">PT. Rumpun Alam Indonesia</strong><span>All Rights Reserved</span></p>
  <div class="credits">
    <!-- All the links in the footer should remain intact. -->
    <!-- You can delete the links only if you've purchased the pro version. -->
    <!-- Licensing information: https://bootstrapmade.com/license/ -->
    <!-- Purchase the pro version with working PHP/AJAX contact form: [buy-url] -->
  </div>
</div>

</footer>
</template>

<script>
export default {
    name: 'AppFooter'
}
</script>

<style scoped>
.social-links i {
    color: #ffffff;
    stroke: #ffffff;
    stroke-width: 1;
  }

  .social-links a:hover i {
    color: #cccccc;
    stroke: #cccccc;
  }
.white {
  color: white;
  }
</style>